/*
  Style for Homepage
*/

.pagination {
  font-size: 1rem;
  a:hover {
    text-decoration: none;
  }

  .page-item {
    .page-link {
      color: var(--btn-patinator-text-color);
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      text-align: center;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid var(--btn-paginator-border-color);
      font-family: 'Lato', sans-serif;
      background-color: var(--button-bg);
      &:hover {
        background-color: var(--btn-paginator-hover-color);
      }
    }
    &.active {
      .page-link {
        background-color: var(--btn-active-bg);
        border-color: var(--btn-active-border-color);
        box-shadow: 0 0 8px 0 var(--btn-paginator-shadow) !important;
        color: var(--btn-text-color);
      }
    }
    &.disabled {
      cursor: not-allowed;
      .page-link {
        color: rgba(108, 117, 125, 0.57);
        border-color: var(--btn-paginator-border-color);
        background-color: var(--button-bg);
      }
    }
    &:first-child .page-link,
    &:last-child .page-link {
      border-radius: 50%;
    }
    &:not(:last-child) {
      margin-right: 0.7rem;
    }
  } // .page-item

} // .pagination

#post-list {
  margin-top: 1rem;
  padding-right: 0.5rem;

  .post-preview {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--main-border-color);

    a:hover {
      @extend %link-hover;
    }

    h1 {
      font-size: 1.4rem;
      margin: 0;
    }

    .post-meta {
      i {
        font-size: 0.73rem;
      }
      span:not(:last-child) {
        margin-right: 1.2rem;
      }
    }

    .post-content {
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      color: var(--post-list-text-color);
      > p {
        /* Make preview shorter on the homepage */
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .pin {
      > i {
        transform: rotate(45deg);
        padding-left: 3px;
        color: var(--pin-color);
      }
      > span {
        display: none;
      }
    }

  } // .post-preview

} // #post-list

/* Hide SideBar and TOC */
@media all and (max-width: 830px) {
  .pagination {
    justify-content: center;
  }
}

/* Sidebar is visible */
@media all and (min-width: 831px) {

  #post-list {
    margin-top: 1.5rem;
    .post-preview .post-meta {
      .pin {
        background: var(--pin-bg);
        border-radius: 5px;
        line-height: 1.4rem;
        height: 1.3rem;
        margin-top: 3px;
        padding-left: 1px;
        padding-right: 6px;

        > span {
          display: inline;
        }
      }
    }
  }

  .pagination {
    font-size: 0.85rem;
    .page-item .page-link {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

}

/* Pannel hidden */
@media all and (max-width: 1200px) {
  #post-list {
    padding-right: 0;
  }
}
